import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';
import QuelquesChiffres from './QuelquesChiffres';
import Partners from './Partners';
import '../centre-gestion.css'; // Utiliser les mêmes styles que CentreGestion

const LAD = () => {
  return (
    <div>
      <Helmet>
        <title>Livraison à Domicile (L.A.D) - ATE69</title>
        <meta
          name="description"
          content="Découvrez notre service de Livraison à Domicile (L.A.D) : un service dédié à nos clients réguliers pour la livraison de véhicules en toute simplicité et sécurité."
        />
        <link rel="canonical" href="https://ate69.fr/lad" />
        <html lang="fr" />
      </Helmet>
      <Header />
      <section
        id="lad"
        className="centre-gestion-section"
        itemScope
        itemType="http://schema.org/Service"
      >
        <div className="centre-gestion-container">
          <div className="centre-gestion-content">
            <h6>Livraison à Domicile (L.A.D)</h6>
            <h3>Un service dédié à nos clients réguliers</h3>
            <p itemProp="description">
              Avec notre service de Livraison à Domicile (L.A.D), profitez d’une
              solution de transport sur-mesure pour vos véhicules. Ce service,
              réservé à nos clients réguliers, garantit une livraison
              professionnelle et sécurisée directement chez vos clients.
            </p>
            <p>
              Notre forfait à 149€ TTC couvre un rayon de 30 km autour de Lyon
              (69), incluant :
            </p>
            <ul>
              <li>Démonstration complète du véhicule</li>
              <li>Essai routier pour une prise en main optimale</li>
              <li>Gestion des documents nécessaires (cession, carte grise, etc.)</li>
            </ul>
            <p>
              Que ce soit pour la livraison de véhicules neufs ou d’occasion,
              nous nous engageons à offrir une expérience client irréprochable.
            </p>
          </div>
        </div>
      </section>

      <section id="lad-details" className="work-section">
        <div className="work-container">
          <h2>Les avantages de notre service L.A.D</h2>
          <div className="work-row">
            <div className="work-card">
              <img src={require('../../src/img/img_card1.jpg')} alt="Livraison personnalisée" />
              <h3>Un service personnalisé</h3>
              <p>
                Nous adaptons chaque livraison aux besoins spécifiques de vos
                clients, en garantissant ponctualité et professionnalisme.
              </p>
            </div>

            <div className="work-card">
              <img src={require('../../src/img/img_card2.jpg')} alt="Tarif national" />
              <h3>Tarif national</h3>
              <p>
                Avec un tarif clair et compétitif, notre service est disponible
                partout en France pour accompagner le développement de votre
                activité.
              </p>
            </div>

            <div className="work-card">
              <img src={require('../../src/img/img_card3.jpg')} alt="Clients réguliers" />
              <h3>Réservé aux clients réguliers</h3>
              <p>
                Notre service est exclusivement réservé aux professionnels ayant
                réalisé au moins 10 convoyages avec ATE69, garantissant un
                partenariat de qualité.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section id="lad-promotion" className="promotion-section">
        <div className="promotion-container">
          <h2>Un service conçu pour les professionnels</h2>
          <p>
            En choisissant notre service de Livraison à Domicile, vous offrez à
            vos clients une prestation haut de gamme tout en simplifiant la
            gestion de vos livraisons.
          </p>
          <Link to="/contact" className="promotion-button">
            Contactez-nous pour en savoir plus
          </Link>
        </div>
      </section>

      <QuelquesChiffres />
      <Partners />
      <Footer />
    </div>
  );
};

export default LAD;
