import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import ConvoyageForm from '../../pages/client/ConvoyageForm';
import LADForm from '../../pages/client/LAD_Form';
import SentForms from '../../components/ClientForms/SentForms';
import FormCard from '../../components/ClientForms/FormCard';
import '../../ClientDashboard.css';
import axios from 'axios';

function ClientDashboard() {
    const navigate = useNavigate();
    const [clientInfo, setClientInfo] = useState(null);
    const [loading, setLoading] = useState(true);
    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [error, setError] = useState('');
    const [showConvoyageForm, setShowConvoyageForm] = useState(false);
    const [encoursColor, setEncoursColor] = useState('#000');
    const [encoursRestant, setEncoursRestant] = useState(0);
    const [formType, setFormType] = useState('convoyage');

    const pendingRef = useRef(null);
    const validatedRef = useRef(null);
    const rejectedRef = useRef(null);
    const startedRef = useRef(null);
    const completedRef = useRef(null);
    const finalizedRef = useRef(null);

useEffect(() => {
    const token = localStorage.getItem('token');
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    const customIdC = user.customIdC;

    if (!token || !customIdC) {
        navigate('/login');
        return;
    }

    let isMounted = true;

    const fetchClientInfo = async () => {
        try {
            const headers = { Authorization: `Bearer ${token}` };

            // Récupération des données du client
            const clientResponse = await axios.get(`https://ate69.fr:5001/api/clients/${customIdC}`, { headers });
            const clientData = clientResponse.data;

            // Récupération des convoyages
            const convoyagesResponse = await axios.get(`https://ate69.fr:5003/api/convoyages`, { headers });
            const allConvoyages = convoyagesResponse.data;

            // Séparation des convoyages rejetés et valides
            const rejectedConvoyages = allConvoyages.filter(
                (conv) => conv.clientDetails.customIdC === customIdC && conv.status === 'rejected'
            );
            const validConvoyages = allConvoyages.filter(
                (conv) => conv.clientDetails.customIdC === customIdC && conv.status !== 'rejected'
            );

            // Calcul des données financières uniquement avec les convoyages valides
            const totalConvoyage = validConvoyages.reduce((sum, conv) => sum + (conv.pricingDetails.totalPrice || 0), 0);
            const totalAdvancePaid = validConvoyages.reduce((sum, conv) => sum + (conv.pricingDetails.advancePaid || 0), 0);
            const totalFinalAmountDue = totalConvoyage - totalAdvancePaid;
            const encoursMax = clientData.financialInfo.encoursMax || 0;
            const encoursRestant = encoursMax - totalFinalAmountDue;

            // Déterminer la couleur de l'encours
            const encoursPourcentage = (encoursRestant / encoursMax) * 100;
            let couleurEncours = 'black';
            if (encoursPourcentage > 25) couleurEncours = 'green';
            else if (encoursPourcentage > 10) couleurEncours = 'orange';
            else if (encoursPourcentage >= 0) couleurEncours = 'red';

            // Mettre à jour l'état si le composant est toujours monté
            if (isMounted) {
                setEncoursColor(couleurEncours);
                setEncoursRestant(encoursRestant);
                setClientInfo({
                    ...clientData,
                    financialInfo: {
                        ...clientData.financialInfo,
                        totalConvoyage,
                        totalAdvancePaid,
                        totalFinalAmountDue,
                        encoursRestant,
                        encoursMax,
                        pendingCount: validConvoyages.filter((conv) => conv.status === 'pending').length,
                        validatedCount: validConvoyages.filter((conv) => conv.status === 'validated').length,
                        startedCount: validConvoyages.filter((conv) => conv.status === 'started').length,
                        terminatedCount: validConvoyages.filter((conv) => conv.status === 'terminated').length,
                        finishedCount: validConvoyages.filter((conv) => conv.status === 'finished').length,
                        rejectedCount: validConvoyages.filter((conv) => conv.status === 'rejected').length,
                    },
                });
                setShowConvoyageForm(encoursPourcentage >= 0);
                setLoading(false);
            }
        } catch (error) {
            console.error('Erreur lors de la récupération des informations du client:', error);
            navigate('/login');
        }
    };

    fetchClientInfo();

    return () => {
        isMounted = false; // Cleanup pour éviter les mises à jour après démontage
    };
}, [navigate]);

    const getGreeting = () => {
        const hour = new Date().getHours();
        if (hour < 12) return 'Bonjour';
        if (hour < 18) return 'Bon après-midi';
        return 'Bonsoir';
    };

    const scrollToSection = (ref) => {
        if (ref && ref.current) {
            window.scrollTo({
                top: ref.current.offsetTop,
                behavior: 'smooth',
            });
        }
    };

    const handleSearch = async () => {
        if (searchTerm.trim() === '') {
            setError('Veuillez entrer un terme de recherche.');
            return;
        }

        try {
            const token = localStorage.getItem('token');
            if (!token) throw new Error('Token non fourni.');

            const response = await axios.get(`https://ate69.fr:5003/api/convoyages/search/${searchTerm}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            setSearchResults(response.data);
            setError('');
        } catch (error) {
            console.error('Erreur lors de la recherche des formulaires:', error);
            setError('Erreur lors de la recherche des formulaires');
        }
    };

    const formatAddress = (address) => {
        if (!address) return 'Adresse non disponible';
        const { streetName, number, city, postalCode, streetType } = address;
        return `${number} ${streetType} ${streetName}, ${city}, ${postalCode}`;
    };

    if (loading) {
        return <div>Chargement des informations...</div>;
    }

    return (
        <div>
            <h1>Tableau de Bord Client ATE69</h1>
            {clientInfo && (
                <>
                    <p>{`${getGreeting()}, ${clientInfo.gender === 'male' ? 'Mr.' : 'Mme.'} ${clientInfo.surname} ${clientInfo.name}!`}</p>
                    <div className="dashboard-container">
                        {/* Informations client */}
                        <div className="client-info-card">
                            <h2>Société</h2>
                            <div className="info-item">
                                <span>Nom:</span>
                                <span>{clientInfo.companyName}</span>
                            </div>
                            <div className="info-item">
                                <span>Adresse:</span>
                                <span>{formatAddress(clientInfo.companyAddress)}</span>
                            </div>
                            <div className="info-item">
                                <span>Type d'entreprise:</span>
                                <span>{clientInfo.companyType}</span>
                            </div>
                            <div className="info-item">
                                <span>Téléphone:</span>
                                <span>{clientInfo.phone}</span>
                            </div>
                            <div className="info-item" style={{ fontWeight: 'bold', fontSize: '1.2em', color: '#31BAF0' }}>
                                <span>ID Capitale:</span>
                                <span>{clientInfo.customIdC}</span>
                            </div>
                        </div>

                        {/* Contact */}
                        <div className="client-info-card">
                            <h2>Contact</h2>
                            <div className="info-item">
                                <span>Nom:</span>
                                <span>{clientInfo.name}</span>
                            </div>
                            <div className="info-item">
                                <span>Prénom:</span>
                                <span>{clientInfo.surname}</span>
                            </div>
                            <div className="info-item">
                                <span>Email:</span>
                                <span>{clientInfo.email}</span>
                            </div>
                            <div className="info-item">
                                <span>Mobile:</span>
                                <span>{clientInfo.mobile}</span>
                            </div>
                        </div>

                        {/* Activité */}
                        <div className="client-info-card">
                            <h2>Activité</h2>
                            <div className="info-item">
                                <span>Rank:</span>
                                <span>{clientInfo.financialInfo.rank}</span>
                            </div>
                            <div className="info-item">
                                <span>Pending:</span>
                                <span>{clientInfo.financialInfo.pendingCount}</span>
                            </div>
							<div className="info-item">
                                <span>Rejeté:</span>
                                <span>{clientInfo.financialInfo.rejectedCount}</span>
                            </div>
                            <div className="info-item">
                                <span>Validé:</span>
                                <span>{clientInfo.financialInfo.validatedCount}</span>
                            </div>
                            <div className="info-item">
                                <span>Started:</span>
                                <span>{clientInfo.financialInfo.startedCount}</span>
                            </div>
                            <div className="info-item">
                                <span>Terminé:</span>
                                <span>{clientInfo.financialInfo.terminatedCount}</span>
                            </div>
                            <div className="info-item">
                                <span>Finalisé:</span>
                                <span>{clientInfo.financialInfo.finishedCount}</span>
                            </div>
                        </div>

                        {/* Financier */}
                        <div className="client-info-card">
                            <h2>Financier</h2>
                            <div className="info-item">
                                <span>Total convoyage:</span>
                                <span>{clientInfo.financialInfo.totalConvoyage.toFixed(2)} €</span>
                            </div>
                            <div className="info-item">
                                <span>Accompte versé:</span>
                                <span>{clientInfo.financialInfo.totalAdvancePaid?.toFixed(2) || '0.00'} €</span>
                            </div>
                            <div className="info-item">
                                <span>Total dû en cours:</span>
                                <span>{clientInfo.financialInfo.totalFinalAmountDue?.toFixed(2) || '0.00'} €</span>
                            </div>
                            <div className="info-item" style={{ fontWeight: 'bold', fontSize: '1em' }}>
                                <span>Encours attribué:</span>
                                <span>{clientInfo.financialInfo.encoursMax?.toFixed(2)} €</span>
                            </div>
                            <div className="info-item" style={{ color: encoursColor, fontWeight: 'bold', fontSize: encoursColor === 'black' ? '1.5em' : '1em' }}>
                                <span>Encours restant:</span>
                                <span>{encoursRestant.toFixed(2)} €</span>
                            </div>
                            {encoursRestant <= 0 && (
                                <div style={{ color: 'red', fontWeight: 'bold', fontSize: '1.2em' }}>
                                    <span>Seuil d’encours atteint. Veuillez contacter l’administratif.</span>
                                </div>
                            )}
                        </div>

                        {/* Recherche */}
                        <div className="client-info-card">
                            <h2>Rechercher</h2>
                            <div className="search-bar">
                                <input 
                                    type="text" 
                                    placeholder="Rechercher des formulaires..." 
                                    value={searchTerm} 
                                    onChange={(e) => setSearchTerm(e.target.value)} 
                                />
                                <button onClick={handleSearch}>Rechercher</button>
                            </div>
                            {error && <div className="error-message">{error}</div>}
                            <div className="buttons-container">
                                <button className="section-button" onClick={() => scrollToSection(pendingRef)}>Pending</button>
                                <button className="section-button" onClick={() => scrollToSection(validatedRef)}>Validated</button>
                                <button className="section-button" onClick={() => scrollToSection(rejectedRef)}>Rejected</button>
                                <button className="section-button" onClick={() => scrollToSection(startedRef)}>Started</button>
                                <button className="section-button" onClick={() => scrollToSection(completedRef)}>Completed</button>
                                <button className="section-button" onClick={() => scrollToSection(finalizedRef)}>Finalized</button>
                            </div>
                        </div>
                    </div>

                    {/* Section pour la sélection du service */}
                    <div>
                        <h2>Réservez votre service</h2>
                        <div className="button-group">
                            <button onClick={() => setFormType('convoyage')} className={formType === 'convoyage' ? 'active' : ''}>
                                Convoyage
                            </button>
                            <button onClick={() => setFormType('LAD')} className={formType === 'LAD' ? 'active' : ''}>
                                Livraison à Domicile (L.A.D)
                            </button>
                        </div>
                        {showConvoyageForm ? (
                            formType === 'convoyage' ? (
                                <ConvoyageForm clientInfo={clientInfo} />
                            ) : (
                                <LADForm clientInfo={clientInfo} />
                            )
                        ) : (
                            <p style={{ color: 'red', fontWeight: 'bold' }}>Seuil d’encours atteint. Veuillez contacter l’administratif.</p>
                        )}
                    </div>

                    {/* Section pour l’affichage des formulaires envoyés */}
                    <div className="form-status-blocks">
                        <div ref={pendingRef}>
                            <h2>Formulaires Pending</h2>
                            <SentForms customIdC={clientInfo.customIdC} status="pending" />
                        </div>
                        <div ref={validatedRef}>
                            <h2>Formulaires Validated</h2>
                            <SentForms customIdC={clientInfo.customIdC} status="validated" />
                        </div>
                        <div ref={rejectedRef}>
                            <h2>Formulaires Rejected</h2>
                            <SentForms customIdC={clientInfo.customIdC} status="rejected" />
                        </div>
                        <div ref={startedRef}>
                            <h2>Formulaires Started</h2>
                            <SentForms customIdC={clientInfo.customIdC} status="started" />
                        </div>
                        <div ref={completedRef}>
                            <h2>Formulaires Completed</h2>
                            <SentForms customIdC={clientInfo.customIdC} status="terminated" />
                        </div>
                        <div ref={finalizedRef}>
                            <h2>Formulaires Finalized</h2>
                            <SentForms customIdC={clientInfo.customIdC} status="finished" />
                        </div>
                    </div>

                    {/* Affichage des résultats de recherche */}
                    {searchResults.length > 0 && (
                        <div className="search-results">
                            <h2>Résultats de la recherche</h2>
                            {searchResults.map(result => (
                                <FormCard key={result._id} form={result} />
                            ))}
                        </div>
                    )}
                </>
            )}
        </div>
    );
}

export default ClientDashboard;
